<template>
    <div class="container py-16 md:py-20">
        <h3 class="font-display text-primary text-2xl md:text-3xl lg:text-[40px] font-semibold mb-4">Ogni box è una sorpresa!</h3>
        <p class="text-brand-gray font-thin text-sm md:text-base">Scarta insieme alle mamme la box e scopri cosa potresti trovare</p>
        <div class="mt-10 -mr-5 -mx-3">
            <Swiper
                :modules="[SwiperPagination]"
                :slides-per-view="1.8"
                :pagination="{
                    el: '.swiper-pagination'
                }"
                :breakpoints="{
                    991: {
                        slidesPerView: 3
                    },
                    1280: {
                        slidesPerView: 4
                    },
                }">
                <SwiperSlide v-for="video in videos" class="lg:!w-1/4">
                    <HomeVideosItem :item="video" />
                </SwiperSlide>
                <div class="swiper-pagination mt-4 w-full relative"></div>
            </Swiper>
        </div>
    </div>
</template>

<script setup>
//replace with videos from source
const videos = [
    {
        src: 'https://storage.googleapis.com/pnt-happybox/hb-landing/Martypan_Happy_Box_Pre%CC%81natal.mov',
        image: 'https://storage.googleapis.com/pnt-happybox/hb-landing/MartyPan.jpg'
    },
    {
        src: 'https://storage.googleapis.com/pnt-happybox/hb-landing/crivello.mov',
        image: 'https://storage.googleapis.com/pnt-happybox/hb-landing/thumbnail_crivello.jpg'
    },
    {
        src: 'https://storage.googleapis.com/pnt-happybox/hb-landing/01-braidotti-2022.mp4',
        image: 'https://storage.googleapis.com/pnt-happybox/hb-landing/01-braidotti-2022.jpg'
    },
    {
        src: 'https://storage.googleapis.com/pnt-happybox/hb-landing/SPINK.mov',
        image: 'https://storage.googleapis.com/pnt-happybox/hb-landing/adriana_spink_thumbnail.jpg'
    }
];
</script>
